// Generated by Framer (76f10ba)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {JCq4jrqlo: {hover: true}};

const cycleOrder = ["JCq4jrqlo"];

const variantClassNames = {JCq4jrqlo: "framer-v-iavy4l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "JCq4jrqlo", link: BSKlqNomH, title: v6nr7iwH1 = "Home", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "JCq4jrqlo", variant, transitions, variantClassNames, enabledGestures, cycleOrder})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wfFrk", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={BSKlqNomH} openInNewTab={false}><motion.a {...restProps} className={cx("framer-iavy4l", className)} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopRightRadius: 8, borderTopLeftRadius: 8, backgroundColor: "rgb(255, 255, 255)", ...style}} layoutId={"JCq4jrqlo"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Variant 1"} ref={ref} variants={{"JCq4jrqlo-hover": {backgroundColor: "rgb(240, 240, 240)"}}}><RichText className={"framer-ixiqi3"} style={{"--framer-paragraph-spacing": "0px", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", whiteSpace: "pre"}} layoutId={"JCq4jrqloDlpocCiR1"} transition={transition} layoutDependency={layoutDependency} fonts={["GF;Outfit-300"]} withExternalLayout={true} verticalAlignment={"top"} __fromCanvasComponent={true} __htmlStructure={"<p><span style=\"--framer-font-family:&quot;Outfit&quot;, serif; --framer-font-style:normal; --framer-font-weight:300; --font-selector:R0Y7T3V0Zml0LTMwMA==; --framer-letter-spacing:-0.5px; --framer-text-transform:uppercase;\">{{ text-placeholder }}</span></p>"} htmlFromDesign={"<p><span style=\"--framer-font-family:&quot;Outfit&quot;, serif; --framer-font-style:normal; --framer-font-weight:300; --font-selector:R0Y7T3V0Zml0LTMwMA==; --framer-letter-spacing:-0.5px; --framer-text-transform:uppercase;\">Home</span></p>"} textFromDesign={v6nr7iwH1} {...addPropertyOverrides({"JCq4jrqlo-hover": {fonts: ["GF;Outfit-300"], __htmlStructure: "<p><span style=\"--framer-font-family:&quot;Outfit&quot;, serif; --framer-font-style:normal; --framer-font-weight:300; --font-selector:R0Y7T3V0Zml0LTMwMA==; --framer-letter-spacing:-0.5px; --framer-text-transform:uppercase;\">{{ text-placeholder }}</span></p>"}}, baseVariant, gestureVariant)}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wfFrk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wfFrk * { box-sizing: border-box; }", ".framer-wfFrk .framer-iavy4l { position: relative; width: min-content; height: min-content; display: flex; flex-direction: row; justify-content: flex-start; align-content: center; align-items: center; flex-wrap: nowrap; gap: 10px; padding: 6px 15px 6px 15px; text-decoration: none; }", ".framer-wfFrk .framer-ixiqi3 { position: relative; width: auto; height: auto; flex: none; white-space: pre; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wfFrk framer-iavy4l > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-wfFrk framer-iavy4l > :first-child { margin-left: 0px; } .framer-wfFrk framer-iavy4l > :last-child { margin-right: 0px; } }", ".framer-wfFrk.framer-v-iavy4l .framer-iavy4l { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 75
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"US5Pbm3w5":{"layout":["auto","auto"]}}}
 * @framerVariables {"BSKlqNomH":"link","v6nr7iwH1":"title"}
 */
const FramerJlXUNicBT: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerJlXUNicBT;

FramerJlXUNicBT.displayName = "Menu item";

FramerJlXUNicBT.defaultProps = {width: 75, height: 32};

addPropertyControls(FramerJlXUNicBT, {BSKlqNomH: {type: ControlType.Link, title: "Link"}, v6nr7iwH1: {type: ControlType.String, title: "Title", defaultValue: "Home", displayTextArea: false}} as any)

addFonts(FramerJlXUNicBT, [{url: "https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf", family: "Outfit", style: "normal", weight: "300", moduleAsset: {url: "https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf", localModuleIdentifier: "local-module:canvasComponent/JlXUNicBT:default"}}])